
//Login.vue

<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        style="
          background-image: url(https://dejlatravel.com/images/bg01.jpg);
          background-repeat: no-repeat;
          background-size: cover;
        "
        class="fill-height"
        fluid
      >
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-form v-model="value" ref="form">
              <v-card style="opacity: 0.95" flat class="elevation-12">
                <v-card-title>
                  {{ $t("login") }}
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    dense
                    outlined
                    :disabled="show"
                    prepend-inner-icon="mdi-email"
                    v-model="form.email"
                    hide-details="auto"
                    :rules="rules.required"
                    :placeholder="$t('email')"
                    :label="$t('email')"
                    type="text"
                  >
                  </v-text-field>
                  <v-text-field
                    class="mt-4"
                    dense
                    outlined
                    v-if="show"
                    v-model="form.password"
                    prepend-inner-icon="mdi-lock"
                    hide-details="auto"
                    :rules="rules.required"
                    clearable
                    @click:clear="Clickclear()"
                    :placeholder="$t('Password')"
                    :label="$t('Password')"
                    type="password"
                  >
                  </v-text-field>
                </v-card-text>
                <v-card-actions class="justify-center text-h5">
                  <v-btn
                    v-if="!show"
                    :disabled="!value"
                    @click="show = true"
                    text
                  >
                    {{ $t("login") }}
                  </v-btn>
                  <v-btn
                    :loading="loading"
                    v-if="show"
                    :disabled="!value"
                    @click="submit"
                    text
                  >
                    {{ $t("login") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
<snackbar-alert></snackbar-alert>
  </v-app>
</template>



<script>
import { mapActions, mapMutations } from "vuex";
import axios from '../axios/axios';
import SnackbarAlert from '../components/gen/SnackbarAlert.vue';
export default {
  name: "Auth",
  components: {SnackbarAlert},
  data() {
    return {
      value: false,
      show: false,
      loading: false,
      form: {
        email: "",
        password: "",
      },
      password: null,
      showPass: false,
      rules: {
        required: [(v) => !!v || this.$t("rules_required")],
      },
    };
  },
  computed: {
    params() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    ...mapActions("Api", ["post"]),
    ...mapMutations(["setToken", "setUser"]),
    ...mapActions('snackBar', ["setSnackBars"]),
    async submit() {
      this.loading = true;
      this.post({ url: "/login", form: this.form })
        .then((res) => {
           console.log(res);
          localStorage.setItem("token", res.data.access_token);
          this.setToken(res.data.access_token);
         this.getUserInfo(res.data.access_token);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
           let message = error.response ? error.response.data.message : error.message;
                this.setSnackBars({color: "error", message: message});
          console.log(error.response.data.message);
        });
    },
    Clickclear() {
      this.show = false;
      this.form.password = '';
    },
     getUserInfo(value) {
      try {
       this.loading = true;
       new Promise(() => {
        axios.request(
            {
                method: 'get',
                url: `/users/me`,
                headers: {
                   Authorization: `Bearer ${value}`
                }
            },
            ).then((res) => {
             this.loading = false;
             this.setUser(res.data.data);
                        localStorage.setItem('user', JSON.stringify(res.data.data));
                         this.$router.push({ name: "Home" });
            // this.stats = res.data.data;
            console.log(res.data.data);
        }).catch(() => {
            console.log("error");
        });
    })
       
      } catch {
        console.log("error");
      }

    },
    clear() {
      // you can use this method to clear login form
      this.email = "";
      this.password = null;
    },
  },
};
</script>